.unit-image-gallery-v3 {
	margin-right: -5px;
	margin-left: -5px;
	margin-top: -5px;

	.visible-print {
		display: none !important;
	}

	.unitGallery {
		&.gal-video {
			.video-bl {
				height: 220px;
				width: 30%;
				float: left;
				display: block;
				padding: 5px;

				@media (max-width: 767px) {
					width: 100%;
					height: 295px;
				}

				@media (max-width: 450px) {
					height: 205px;
				}

				iframe {
					width: 100%;
					height: 100%;
				}
			}

			a {
				display: none;
				padding: 5px;
				height: 150px;

				&:nth-child(3),
				&:nth-child(2) {
					height: 220px;
				}

				@media (max-width: 767px) {
					padding: 5px;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&:nth-child(2) {
					width: 70%;
					float: right;
					display: block;
					height: 440px;
					overflow: hidden;

					@media (max-width: 767px) {
						width: 100%;
						height: 300px;
					}
				}


				&:nth-child(3),
				&:nth-child(1) {
					width: 30%;
					float: left;
					display: block;

					@media (max-width: 767px) {
						width: 50%;
						height: 140px;
					}
				}


			}
		}

		a {
			padding: 5px;
			height: 150px;
			display: none;

			&:nth-child(3),
			&:nth-child(2) {
				height: 220px;
			}

			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 70%;
				float: right;
				display: block;
				height: 440px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 300px;
				}
			}


			&:nth-child(3),
			&:nth-child(2) {
				width: 30%;
				float: left;
				display: block;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(4) {
				clear: both;

				@media (max-width: 767px) {
					clear: none;
					height: 140px;
					display: none !important;
				}
			}

			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8) {
				display: block;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(8) {
				position: relative;

				&:before {
					content: "+ mehr";
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
				}

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}
		}



	}

}