.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/Kuestenpano_10_310713.jpg);
	display: none;
	height: 300px;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.parallax-box {
		background-color: var(--color-secondary);
		padding: 20px;
		min-height: 400px;

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-white);
		}
	}

}