.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;
		padding-left: 25px;
		position: relative;

		&:before,
		&:after {
			top: 5px;
			position: absolute;
			left: 0;
			margin-left: 0;
		}

	}

	.checkbox-success {
		input[type="checkbox"] {
			display: none;
		}
	}

	.red {
		color: var(--color-red);
	}
}