.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.fa-star {
		width: 20px;
		height: 20px;
		background-size: cover;
		background-image: url(RESOURCE/img/icon_star.png);

		&:before {
			display: none;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
			}

			.fa-balcony {
				width: 21px;
				margin-right: 9px;
			}

			.fa-sky {}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
		margin-right: 5px;
	}

}