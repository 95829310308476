.unit-pricelist-v1 {


	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}

	.instructions {
		padding: 1.5rem 0.75rem 3.5rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}
}