.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 35px;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}

	.unit-description-v2 {
		.showLessText {
			position: relative;

			&.showFull {
				.show-bl {
					display: block;
				}


				.hide-show-btn {

					&:after {
						content: "\f106";
					}
				}
			}

			.show-bl {
				display: none;
			}

			.first {
				display: block;
			}

			.hide-show-btn {
				position: absolute;
				bottom: -14px;
				left: 50%;
				transform: translate(-50%, 0);
				padding: 5px 15px;
				background-color: var(--white);
				display: inline-block;
				border: 1px solid var(--btn-border-color-hide-show);
				font-size: var(--font-size-md);

				&:focus,
				&:hover {
					text-decoration: none;
				}

				&:after {
					content: "\f107";
					margin-left: 10px;
					margin-left: 5px;
					font-family: fontawesome;
				}
			}
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}
	}

	.stars {
		display: flex;



		.fa-star-o {
			display: none;
		}
	}

	.view360 {
		iframe {
			width: 100%;
			height: 450px;

			@media(max-width: 767px) {
				height: 350px;
			}
		}
	}

}