.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);
	padding-bottom: 40px;

	.contact-img {
		width: 100%;
		max-width: 320px;


		img {
			max-width: 100%;
		}
	}
}