.last-minute-unit {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-around;

	.single-unit {
		margin: 0 auto 20px;
		max-width: 350px;
		transition: all .25s ease 0s;


		.content {
			min-height: 250px;
			/* justify-content: flex-start; */

			.flex-1 {}

			.flex-2 {}
		}

		.alternatives {
			padding-top: 20px;

			.altheading {
				font-weight: 700;
				font-size: var(--font-size-md);
			}

			a {
				font-size: var(--font-size-md);
				text-decoration: none;
				color: var(--color-secondary);

				&:hover {
					text-decoration: underline;
				}
			}

			.invalid {
				text-decoration: line-through;
			}

			.special {
				color: red;
				font-weight: 700;
			}

			.fa {
				font-size: var(--font-size-sm);
				color: var(--color-secondary);
			}
		}

	}
}