.about-us-v1 {
	margin-top: var(--page-margin-top);

	.heading1 {
		small {
			font-size: 70%;
		}
	}

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */

	.blogcontent {

		img {
			max-height: 360px !important;
			width: 100%;
			height: 100%;
			object-fit: fill;
			padding: 0 !important;
			border: none !important;
			aspect-ratio: 4 / 3;
		}

		button {
			margin-top: 30px;
		}
	}

	.angebote-boxes-v1 {

		.blogcontent {
			display: flex;
			flex-direction: column;
			align-items: center;
			border: 1px solid var(--overlay-color);

			.img-thumbnail {
				aspect-ratio: 4 / 3;
				border-radius: unset;
			}

			a {
				text-align: center;
			}

			button {
				margin-bottom: 10px;

				@media(min-width: 769px) {
					font-size: 1rem;
					width: 90%;
				}

			}

		}

		@media(max-width: 767px) {
			.row {
				margin: 0;
				gap: 5px;
			}
		}

		@media(min-width: 768px) {
			.row {
				.col-md-6 {
					flex: 0 0 33%;
				}
			}
		}

	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}