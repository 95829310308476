.privacy-v1 {
	margin-top: var(--page-margin-top);
	padding-bottom: 80px;

	table {

		th,
		td {
			@media (max-width:550px) {
				width: 100%;
				display: block;
			}
		}
	}

	a,
	p {
		word-break: break-word;
	}
}