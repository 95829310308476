#lastviewed-units {
	.container-fluid {
		padding-bottom: 110px !important;
	}
}

.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);
		padding-top: 110px;
		position: relative;

		.newsletter-post-bl {
			top: -50px;
			position: absolute;
			z-index: 5;
			width: 100%;
			padding: 0 20px;

			@media (max-width:550px) {
				top: -70px;
			}

			.newslet-bl {
				background-color: #426b89;
				padding: 20px;
				text-align: center;
				padding: 15px;
				border-radius: 10px;
				max-width: 1120px;
				margin: 0 auto;

				p {
					color: #fff;
					margin: 0 0 5px;
					font-size: 13px;

					@media (max-width:550px) {
						font-size: 12px;
					}
				}
			}

			.news-heading {
				margin-bottom: 25px;
			}

			.input-box-newsletter {
				margin-bottom: 0;
				max-width: 420px;
				margin: 0 auto;
				/*	border: 4px solid #ffffff;*/
				background-color: #ffffff;
				padding-left: 15px !important;
				border-radius: 30px;

				.form-control {
					background-color: transparent;
					color: var(--color-black);
				}

				.btn {
					background-color: #1e69a0;
					border-radius: 30px;
				}

				p:last-child {
					margin-bottom: 0;
				}


				.heading1 {
					color: var(--secondary);
				}


			}
		}


		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		.v-office-logo {
			text-align: right;
			margin-bottom: 35px;

			@media (max-width:1100px) {
				text-align: center;
			}

			@media (max-width:992px) {
				padding: 0 7px;
				text-align: center;
			}
		}

		.flex-cols {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 10px;
		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			max-width: initial;
			width: 100%;
			height: auto;
			border: 10px solid #fff;
			background: #fff;
			margin-bottom: 20px;
			border-radius: 5px;
			max-height: 180px;
			height: 100%;
			padding: 0;
			max-width: 350px;
			object-fit: cover;

			@media (max-width:992px) {
				max-height: inherit;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}

		.dtv-image {
			margin-top: -6px;
			max-height: 142px;
		}
	}

	.footer-area {
		display: flex;
		justify-content: space-between;
		/*max-width: 980px;
		margin: 0 auto;*/

		@media (max-width:992px) {
			display: block;
		}

		.col-box {
			margin-bottom: 30px;
			padding: 0 15px;

			@media (max-width:992px) {
				padding: 0 7px;
				text-align: center;
			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {

			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}